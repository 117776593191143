// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/backgrounds/bg-right-bubble.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/backgrounds/bg-left-bubble.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".wrap[data-v-904fffdc] {\n  background-color: #f2f4fa;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat, no-repeat;\n}\n@media all and (min-width: calc(700px + 1px)) {\n.wrap[data-v-904fffdc] {\n    padding: 100px 0;\n    background-position: right top, left 80px;\n}\n}\n@media all and (min-width: calc(500px + 1px)) and (max-width: 700px) {\n.wrap[data-v-904fffdc] {\n    padding: 50px 0;\n    background-position: calc(100% + 35px) -70px, -60px 80px;\n    background-size: 100px, 200px;\n}\n}\n@media all and (max-width: 500px) {\n.wrap[data-v-904fffdc] {\n    padding: 50px 0;\n    background-position: calc(100% + 35px) -70px, -60px 80px;\n    background-size: 100px, 170px;\n}\n}\n.subtitle[data-v-904fffdc] {\n  max-width: 550px;\n  margin: 20px auto 0 auto;\n  line-height: 25px;\n}\n.press-item[data-v-904fffdc] {\n  background-color: white;\n  border-radius: 5px;\n  overflow: hidden;\n}\n.press-item .img-asset[data-v-904fffdc] {\n  height: 220px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n.press-item .img-asset.light[data-v-904fffdc] {\n  background-color: #184f90;\n}\n.press-item .img-asset > img[data-v-904fffdc] {\n  width: 70%;\n}\n.btn[data-v-904fffdc] {\n  font-size: 13px !important;\n  font-weight: 600 !important;\n  padding: 11px 26px !important;\n}", ""]);
// Exports
module.exports = exports;
