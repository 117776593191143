var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c("v-container", [
        _c("div", { staticClass: "page-container" }, [
          _c(
            "div",
            { staticClass: "page-title text-center" },
            [
              _c("h1", [_vm._v(_vm._s(_vm.$t("footer.press-kit.header")))]),
              _c(
                "i18n",
                {
                  staticClass: "h5 text-center mt-3",
                  attrs: { path: "footer.press-kit.subtext", tag: "div" },
                },
                [
                  _c(
                    "a",
                    {
                      attrs: {
                        slot: "mediumlink",
                        href: "https://medium.com/myetherwallet",
                        target: "_blank",
                        rel: "noopener noreferrer",
                      },
                      slot: "mediumlink",
                    },
                    [_vm._v(" Medium blog ")]
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        slot: "hyperlink",
                        href: "mailto:mew@dittopr.co",
                        target: "_blank",
                        rel: "noopener noreferrer",
                      },
                      slot: "hyperlink",
                    },
                    [_vm._v(" mew@dittopr.co ")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row", staticStyle: { "margin-top": "70px" } },
            _vm._l(_vm.pressAssets, function (p, key) {
              return _c(
                "div",
                { key: key, staticClass: "col-sm-6 col-md-4 mb-5" },
                [
                  _c("div", { staticClass: "press-item" }, [
                    _c("div", { staticClass: "img-asset", class: p.color }, [
                      _c("img", {
                        style: p.style,
                        attrs: { src: p.png, alt: "Image Asset" },
                      }),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "py-5 d-flex align-center justify-center",
                      },
                      [
                        _c(
                          "a",
                          { attrs: { href: p.png, target: "_blank" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  outlined: "",
                                  medium: "",
                                  fab: "",
                                  color: "greenPrimary",
                                },
                              },
                              [_vm._v(" PNG ")]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "px-2" }),
                        _c(
                          "a",
                          { attrs: { href: p.svg, target: "_blank" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  outlined: "",
                                  medium: "",
                                  fab: "",
                                  color: "greenPrimary",
                                },
                              },
                              [_vm._v(" SVG ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }